import { createFileRoute, redirect } from "@tanstack/react-router";
import { ArrowRightIcon, Loader2Icon } from "lucide-react";
import { useCallback, useState } from "react";

import { getApplications } from "@/api";
import { useAPI } from "@/contexts/api";
import Agreements from "@/routes/-components/agreements";
import { Application } from "@/types";
import { Header } from "@prime/pop-components/src/layout";
import { Button } from "@prime/ui/src/button";
import { useToast } from "@prime/ui/src/toast/use-toast";

export const Route = createFileRoute("/$platformKey/get-started/")({
  beforeLoad: async ({ context }) => {
    const { auth } = context;
    const { isAuthenticated } = auth;

    if (isAuthenticated) {
      let applications: Application[] = [];

      try {
        const response = await getApplications({
          apiClient: context.apiClient,
        });
        applications = response.applications;
      } catch (err) {
        // Valid case, Do nothing
      }

      if (applications.length) {
        throw redirect({
          to: "/",
        });
      }
    }
  },
  component: Page,
});

declare const gtag: CallableFunction;

function getAllCookies() {
  const cookies = document.cookie.split(";");
  const cookieObj: Record<string, string> = {};
  cookies.forEach((cookie) => {
    const [name, value] = cookie.split("=").map((c) => c.trim());
    cookieObj[name] = value;
  });
  return cookieObj;
}

function Page() {
  const { platformKey } = Route.useParams<{ platformKey: string }>();
  const { auth } = useAPI();
  const { toast } = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const handleClick = useCallback(() => {
    setIsLoading(true);
    const cookies = getAllCookies();

    if (gtag) {
      gtag("event", "conversion", {
        send_to: "AW-11489805978/QXx5CND_nvgZEJqN4uYq",
        gclid: cookies.gclid,
      });
    }
    auth
      .signInWith({
        connection: platformKey,
        allScopes: true,
        state: { autoClaim: true, gclid: cookies.gclid },
      })
      .catch(() => {
        toast({
          title: "An error occurred",
          description: "Please try again later",
          variant: "destructive",
        });
      })
      .finally(() => setIsLoading(false));
  }, [auth, platformKey, toast]);

  return (
    <div className="flex min-h-screen w-screen flex-col">
      <Header />
      <div className="flex w-full flex-1 items-center justify-center p-6 text-center sm:p-12">
        <div className="flex w-full max-w-[512px] flex-col items-center gap-6 rounded-xl p-6">
          <div className="text-start">
            <div className="text-txt-primary relative inline-block w-full text-left text-2xl font-normal leading-[125%]">
              Before We Get Started
            </div>
            <div className="text-txt-tertiary z-[3] self-stretch text-base leading-[150%]">
              Please review and agree to our policies and disclosures that
              dictate how we’ll be working with you.
            </div>
          </div>

          <div className="bg-surface-inset w-full rounded-xl p-1 sm:p-3">
            <Agreements />
          </div>
          <Button
            className="mt-6 h-12 w-full rounded-xl text-base"
            disabled={isLoading}
            onClick={handleClick}
          >
            <div className="relative font-medium leading-[24px]">Continue</div>

            {isLoading ? (
              <Loader2Icon className="ml-2 h-4 w-4 animate-spin" />
            ) : (
              <ArrowRightIcon size={24} />
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}

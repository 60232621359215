import * as Sentry from "@sentry/react";
import { createFileRoute, redirect } from "@tanstack/react-router";

import LoaderScreen from "@/routes/-components/loader-screen";

declare const gtag: CallableFunction;

export const Route = createFileRoute("/authorize/")({
  loader: async ({ context }) => {
    const { loginWithRedirect } = context.auth;

    const url = new URL(window.location.href);
    const query = new URLSearchParams(url.search);

    // Parse Auth0's "appState" (?state= query param)
    const stateQuery = JSON.parse(query.get("state") ?? "{}");

    // We've added an applicationId or email param to the state query param
    // via the magic link (auth0.WebAuth)
    const {
      applicationId,
      invitationId,
      connection = "",
      autoClaim = false,
      gclid,
    } = stateQuery;

    const claimId = applicationId || invitationId;

    // If there is an Auth0 error, redirect to the home page
    if (query.has("error")) {
      Sentry.captureMessage(
        `Auth0 Error: ${query.get("error")}, ${query.get("error_description")}`
      );
      throw redirect({
        to: "/",
        search: {
          error: "login_failed",
        },
        replace: true,
      });
    }

    if (!query.has("code")) {
      Sentry.captureMessage(`User tried to access /authorize/ without a code`);
      throw redirect({
        to: "/", // Redirect to home page
        replace: true,
      });
    }

    if (claimId) {
      return loginWithRedirect({
        code: query.get("code")!,
        returnTo: "/authorize/claim",
        returnToSearch: { applicationId: claimId },
      }).catch((error) => {
        Sentry.captureException(error);
        throw redirect({
          to: "/",
          search: {
            error: "login_failed",
          },
          replace: true,
        });
      });
    }

    if (gtag) {
      gtag("event", "page_view", {
        send_to: "AW-11489805978/wfflCJqZr_gZEJqN4uYq",
        gclid,
      });
    }

    return loginWithRedirect({
      code: query.get("code")!,
      returnTo: "/authorize/login",
      returnToSearch: connection ? { connection, autoClaim } : undefined,
    }).catch((error) => {
      Sentry.captureException(error);
      throw redirect({
        to: "/",
        search: {
          error: "login_failed",
        },
        replace: true,
      });
    });
  },
  component: Page,
});

function Page() {
  return <LoaderScreen />;
}
